import React, { useState, useEffect } from 'react';
import styles from './ProductAdd.module.css';
import axios from 'axios';
import Spin from '../Spinner/Spinner';
import Popup from '../Popup/Popup';
import Toggle from '../../utils/Toggle/Toggle';
import { API } from '../../config'
import { useNavigate, Link } from 'react-router-dom';
import { BsSave2 } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai'

function ProductAdd() {
    const [categories, setCategories] = useState([]);
    const [spin, setSpin] = useState(true);
    const [category, setCategory] = useState();
    const [sort, setSort] = useState();
    const [name, setName] = useState("Add Product Name");
    const [type, setType] = useState();
    const [desc, setDesc] = useState("");
    const [subDesc, setSubDesc] = useState("");
    const [itemDetails, setItemDetails] = useState();
    const [cost, setCost] = useState(0);
    const [costGST, setCostGST] = useState(0);
    const [custom, setCustom] = useState(0);
    const [customGST, setCustomGST] = useState(0);
    const [margin, setMargin] = useState(0);
    const [image, setImage] = useState(['']);
    const [brand, setBrand] = useState();
    const [available, setAvailable] = useState(false);
    const [customisation, setCustomisation] = useState(false);
    const [show, setShow] = useState(false);
    const [spinn, setSpinn] = useState(false);
    let navigate = useNavigate();

    const getData = async () => {
        const data = await fetch(
            `${API}/category/get`
        )
        const json = await data.json()
        setCategories(json.allcategory);
        setSpin(false);
    }

    useEffect(() => {
        getData()
    }, [])

    const change = () => {
        setShow(false);
    };

    const ImageEntry = () => {
        setImage([...image, `Entry ${image.length}`]);
    };

    const saveImage = (e, index) => {
        let data = [...image];
        data[index] = e.target.value;
        setImage(data);
    }

    const saveData = async (event) => {
        event.preventDefault();
        event.target.reset();
        window.scrollTo(0, 0);
        setSpinn(true);
        const data = {
            categoryId: category,
            sortId: sort,
            name: name,
            description: desc,
            sub_description: subDesc,
            item_Details: itemDetails,
            productType: type,
            cost: cost,
            cost_GST: costGST,
            customisation: custom,
            customisation_GST: customGST,
            margin: margin,
            image: image,
            brandName: brand,
            available: available,
            cust_flag: customisation,
            createdBy: "60e9c7db11ab1428accf8278",
            updatedBy: "60e9c7db11ab1428accf8278",
            deletedBy: "60e9c7db11ab1428accf8278"
        };
        console.log(data);
        axios
            .post(`${API}/Admin/addProduct/`, data)
            .then((res) => {
                console.log('Done')
                setShow(true);
                setSpinn(false);
                console.log(res);
                navigate(`/product/${res.data._id}`);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <>
            {spinn ? <Spin /> : null}
            {show ? <Popup show={show} switch={change} message="Added Successfully" /> : null}
            {spin ? <Spin /> :
                <div className={styles.productcontainer}>
                    <div className={styles.heading}>
                        <div className={styles.upper}>
                            <Link to="/">
                                <span className={styles.swag}>Website
                                    <span className={styles.greater}> &gt; </span>
                                </span>
                            </Link>
                            <Link to="/products">
                                <span className={styles.swag}>Products
                                    <span className={styles.greater}> &gt; </span>
                                </span>
                            </Link>
                            <span className={styles.req}>Add Product</span>
                        </div>
                        <form onSubmit={saveData}>
                            <div className={styles.productdesc}>
                                <div className={styles.leftproductdesc}>
                                    <h1 className={styles.details}>{name}</h1>
                                    <h1 className={styles.iddetails}>ID : To be Generated</h1>
                                    {/* <div className={styles.display}>
                                    <img src={image} className={styles.imagedisplay}></img>
                                </div> */}
                                </div>
                                <div className={styles.rightproductdesc}>
                                    <div className={styles.button}>
                                        <div className={styles.icon}>
                                            <BsSave2 />
                                        </div>
                                        <input
                                            type='submit'
                                            className={styles.bttn}
                                            value='Save'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.items}>
                                <h1 className={styles.head}>Product Information</h1>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Product Name : </div>
                                    <input
                                        name='productname'
                                        placeholder='Product Name'
                                        type='text'
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                        id={styles.gap}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Brand Name : </div>
                                    <input
                                        name='fullname'
                                        placeholder='Brand Name'
                                        type='text'
                                        onChange={event => setBrand(event.target.value)}
                                        id={styles.gap}
                                        value={brand}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Category : </div>
                                    <select
                                        name='category'
                                        id='dropdown'
                                        className={styles.select}
                                        onChange={event => setCategory(event.target.value)}
                                        value={category}
                                        required
                                    >
                                        <option value='' disabled selected hidden> Choose a Category</option>
                                        {
                                            categories.map((c, i) => {
                                                return (
                                                    <option value={c._id}>{c.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Type : </div>
                                    <select
                                        name='producttype'
                                        id='dropdown'
                                        className={styles.select}
                                        onChange={event => setType(event.target.value)}
                                        value={type}
                                        required
                                    >
                                        <option value='' disabled selected hidden> Choose the Type</option>
                                        <option value='C'>Colored</option>
                                        <option value='IP'>Individual Products</option>
                                        <option value='DC'>Dual Colored</option>
                                        <option value='NV'>Named Variants</option>
                                        <option value='UP'>Unquantifiable Products</option>
                                    </select>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Sort Id : </div>
                                    <input
                                        name='sortid'
                                        placeholder='Sort ID'
                                        type='number'
                                        value={sort}
                                        onChange={event => setSort(event.target.value)}
                                        id={styles.gap}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Availability : </div>
                                    <input
                                        name='availability'
                                        placeholder='Availability'
                                        type='text'
                                        value={available ? "Available" : "Not Available"}
                                        onChange={event => setSort(event.target.value)}
                                        id={styles.gap}
                                        disabled
                                    ></input>
                                    <div className={styles.togglebutton}>
                                        <Toggle status={available} setStatus={setAvailable} />
                                    </div>
                                </div>
                                <div className={styles.first}>
                                    <div className={styles.itemhead}>Customisation : </div>
                                    <input
                                        name='customisation'
                                        placeholder='Customisation'
                                        type='text'
                                        value={customisation ? "Available" : "Not Available"}
                                        onChange={event => setSort(event.target.value)}
                                        id={styles.gap}
                                        disabled
                                    ></input>
                                    <div className={styles.togglebutton}>
                                        <Toggle status={customisation} setStatus={setCustomisation} />
                                    </div>
                                </div>
                                <h1 className={styles.head}>Product Details</h1>
                                <div className={styles.second}>
                                    <div className={styles.deschead}>Description</div>
                                    <textarea
                                        name='desc'
                                        placeholder='Description'
                                        type='text'
                                        onChange={event => setDesc(event.target.value)}
                                        id={styles.desc}
                                        value={desc}
                                    // required
                                    ></textarea>
                                </div>
                                <div className={styles.second}>
                                    <div className={styles.deschead}>Sub-Description</div>
                                    <textarea
                                        name='subdesc'
                                        placeholder='Add Product Sub-Description'
                                        type='text'
                                        onChange={event => setSubDesc(event.target.value)}
                                        id={styles.desc}
                                        value={subDesc}
                                    // required
                                    ></textarea>
                                </div>
                                <div className={styles.second}>
                                    <div className={styles.deschead}>Item Details</div>
                                    <textarea
                                        name='fullname'
                                        placeholder='Add Product Details'
                                        type='text'
                                        onChange={event => setItemDetails(event.target.value)}
                                        id={styles.desc}
                                        value={itemDetails}
                                    // required
                                    ></textarea>
                                </div>
                                <div className={styles.second} style={{marginBottom:"60px"}}>
                                    <div className={styles.deschead}>Image URL</div>
                                    {
                                        image.map((i, index) => {
                                            return (
                                                <textarea
                                                    name='image'
                                                    placeholder='Image URL'
                                                    type='url'
                                                    onChange={event => saveImage(event, index)}
                                                    id={styles.desc}
                                                    value={i}
                                                    required
                                                ></textarea>
                                            )
                                        })
                                    }
                                    <input
                                        name='images'
                                        type='button'
                                        value='Add Another Image'
                                        style={{ cursor: "pointer" }}
                                        className={styles.variantbutton}
                                        onClick={ImageEntry}
                                    ></input>
                                </div>
                                <h1 className={styles.pricehead}>Pricing</h1>
                                <div className={styles.pricesubhead}>Product</div>
                                <div className={styles.pricedetails}>
                                    <div className={styles.cost}>
                                        <div className={styles.rupeeicon}>&#8377;</div>
                                        <input
                                            name='cost'
                                            placeholder='Cost'
                                            type='number'
                                            onChange={event => setCost(+event.target.value)}
                                            id={styles.gaphalfirst}
                                            step=".01"
                                            value={cost}
                                            required
                                        ></input>
                                    </div>
                                    <div className={styles.plus}>
                                        <AiOutlinePlus fontSize="36px" marginTop="20px" />
                                    </div>
                                    <input
                                        name='costgst'
                                        placeholder='Cost-GST'
                                        type='number'
                                        onChange={event => setCostGST(+event.target.value)}
                                        id={styles.gaphalf}
                                        step=".01"
                                        value={costGST}
                                        required
                                    ></input>
                                    <div className={styles.percenticon}>%</div>
                                </div>
                                <div className={styles.pricesubhead}>Customisation</div>
                                <div className={styles.pricedetails}>
                                    <div className={styles.cost}>
                                        <div className={styles.rupeeicon}>&#8377;</div>
                                        <input
                                            name='cust'
                                            placeholder='Custom'
                                            type='number'
                                            onChange={event => setCustom(+event.target.value)}
                                            id={styles.gaphalfirst}
                                            step=".01"
                                            value={custom}
                                            required
                                        ></input>
                                    </div>
                                    <div className={styles.plus}>
                                        <AiOutlinePlus fontSize="36px" marginTop="20px" />
                                    </div>
                                    <input
                                        name='custgst'
                                        placeholder='Custom GST'
                                        type='number'
                                        onChange={event => setCustomGST(+event.target.value)}
                                        id={styles.gaphalf}
                                        value={customGST}
                                        step=".01"
                                        required
                                    ></input>
                                    <div className={styles.percenticon}>%</div>
                                </div>
                                <div className={styles.pricesubhead}>Margin</div>
                                <div className={styles.second}>
                                    <input
                                        name='margin'
                                        placeholder='Margin'
                                        type='number'
                                        onChange={event => setMargin(+event.target.value)}
                                        id={styles.pricegap}
                                        value={margin}
                                        step=".01"
                                        required
                                    ></input>
                                    <div className={styles.percenticon}>%</div>
                                </div>
                                <div className={styles.pricesubhead}>Final Cost</div>
                                <div className={styles.second}>
                                    <div className={styles.cost}>
                                        <div className={styles.rupeeicon}>&#8377;</div>
                                        <input
                                            name='total'
                                            placeholder='Final Cost'
                                            type='number'
                                            id={styles.pricegap}
                                            value={customisation ? Math.round(1 * ((cost + (1 * custom)) + (margin * (cost + (1 * custom))))) : Math.round(1 * ((cost + (0 * custom)) + (margin * (cost + (0 * custom)))))}
                                            disabled
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}

export default ProductAdd