import React from 'react'
import styles from './Website.module.css';
import { Link } from "react-router-dom";
import Categories from '../../images/MainPage/Categories.svg';
import Products from '../../images/MainPage/Products.svg';
import Client from '../../images/MainPage/Client.svg';
import FAQ from '../../images/MainPage/FAQ.svg';
import Partner from '../../images/MainPage/Partner.svg';
import Preset from '../../images/MainPage/Preset.svg';
import Team from '../../images/MainPage/Team.svg';
import Testimonials from '../../images/MainPage/Testimonials.svg';

function Website() {
    return (
        <div className={styles.cardscontainer}>
            <Link to="products">
                <div className={styles.cards}>
                    <img src={Products} alt="Admin"></img>
                    <h2>Products</h2>
                </div>
            </Link>
            <Link to="category">
                <div className={styles.cards}>
                    <img src={Categories} alt="Admin"></img>
                    <h2>Categories</h2>
                </div>
            </Link>
            <Link to="category">
                <div className={styles.cards}>
                    <img src={Preset} alt="Admin"></img>
                    <h2>Preset Packs</h2>
                </div>
            </Link>
            <Link to="clientlogos">
                <div className={styles.cards}>
                    <img src={Client} alt="Admin"></img>
                    <h2>Client Logos Home</h2>
                </div>
            </Link>
            <Link to="clientlogospage">
                <div className={styles.cards}>
                    <img src={Client} alt="Admin"></img>
                    <h2>Client Logos Page</h2>
                </div>
            </Link>
            <Link to="sellerlogos">
                <div className={styles.cards}>
                    <img src={Partner} alt="Admin"></img>
                    <h2>Seller Logos</h2>
                </div>
            </Link>
            <Link to="testimonials">
                <div className={styles.cards}>
                    <img src={Testimonials} alt="Admin"></img>
                    <h2>Testimonials</h2>
                </div>
            </Link>
            <Link to="">
                <div className={styles.cards}>
                    <img src={Team} alt="Admin"></img>
                    <h2>Team</h2>
                </div>
            </Link>
            <Link to="">
                <div className={styles.cards}>
                    <img src={FAQ} alt="Admin"></img>
                    <h2>FAQ</h2>
                </div>
            </Link>
        </div>
    )
}

export default Website
