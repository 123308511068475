import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './SellerLogos.module.css';
import Spin from '../Spinner/Spinner';
import {API} from '../../config'
import { FaPlus } from 'react-icons/fa';

function SellerLogos() {
  const [companyLogos, setCompanyLogos] = useState([]);
  const [spin, setSpin] = useState(true);

  const getData = async () => {
    const data = await fetch(
      `${API}/brandLogo`
    )
    const json = await data.json()
    setCompanyLogos(json.brands);
    setSpin(false);
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {spin ? <Spin /> :
        <div className={styles.entirepage}>
          <div className={styles.upper}>
            <Link to="/">
              <span className={styles.swag}>Website
                <span className={styles.greater}> &gt; </span>
              </span>
            </Link>
            <span className={styles.req}>Seller Logos</span>
          </div>
          <div className={styles.heading}>
            Seller Logos
          </div>
          <div className={styles.addingbutton}>
            <Link to='/sellerlogos/add'>
              <div className={styles.button}>
                <FaPlus className={styles.addicon} />
                Add Seller Logo
              </div>
            </Link>
            {/* <div className={styles.button}>
              Add via Sheet
            </div> */}
          </div>
          <div className={styles.logosdisplay}>
            <table className={styles.displaytable}>
              <thead>
                <tr className={styles.sellerrowhead}>
                  {/* <th>ID</th> */}
                  <th>S No.</th>
                  <th>Company Name</th>
                  <th>Logo</th>
                  <th>C value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  companyLogos.map((c, i) => {
                    return (
                      <tr className={styles.sellerrow}>
                        {/* <td>{c._id}</td> */}
                        <td>{c.order_no}</td>
                        <td>{c.brand_name}</td>
                        <td><img src={c.logo_url} alt="Logo" className={styles.logos}></img></td>
                        <td>{c.c_value}</td>
                        <td className={styles.edit}>
                          <Link to={`/sellerlogos/${c._id}`}>
                            <div >Edit</div>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

export default SellerLogos