import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import styles from './TestimonialUpdate.module.css'
import Toggle from '../../utils/Toggle/Toggle';
import Spinner from '../Spinner/Spinner'
import {API} from '../../config'
import Popup from '../Popup/Popup';

function TestimonialUpdate() {
    const [sort, setSort] = useState();
    const [name, setName] = useState();
    const [companyname, setCompanyname] = useState();
    const [image, setImage] = useState();
    const [status, setStatus] = useState(false);
    const [description, setDescription] = useState();
    const [testimonial, setTestimonial] = useState([]);
    const [spin, setSpin] = useState(true);
    const params = useParams();
    const [spinn, setSpinn] = useState(false);
    let navigate = useNavigate();
    const [show, setShow] = useState(false);

    const change = () => {
        setShow(false);
    };

    const getData = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = await fetch(`${API}/testimonial/${params.id}`, requestOptions);
        const data = await response.json();
        setTestimonial(data);
        setSpin(false);
        setSort(data.sortId);
        setName(data.name)
        setImage(data.image);
        setCompanyname(data.companyName);
        setDescription(data.description)
        setStatus(data.status);
    }

    useEffect(() => {
        getData();
    }, [])

    const deleteData = () => {
        axios.delete(`${API}/testimonial/${params.id}`)
        navigate('/testimonials')
    }

    const saveData = async (event) => {
        event.preventDefault();
        event.target.reset();
        window.scrollTo(0, 0);
        setSpinn(true);
        const data = {
            sortId: sort,
            name: name,
            companyName: companyname,
            image: image,
            status: status,
            description: description
        };
        console.log(data);
        axios
            .put(`${API}/testimonial/${params.id}`, data)
            .then((res) => {
                console.log('Done')
                setShow(true);
                setSpinn(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <>
            {spinn ? <Spinner /> : null}
            {show ? <Popup show={show} switch={change} message="Updated Successfully" /> : null}
            {spin ? <Spinner /> :
                <div className={styles.clientpage}>
                    <div className={styles.heading}>
                        <h1 className={styles.details}>Enter the Testimonial Details</h1>
                        <form onSubmit={saveData}>
                            <div className={styles.items}>
                                <div className={styles.first}>
                                    <p>Enter the SORT ID</p>
                                    <input
                                        name='sortId'
                                        placeholder='Sort Id'
                                        type='number'
                                        onChange={event => setSort(event.target.value)}
                                        id={styles.gap}
                                        value={sort}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.second}>
                                    <p>Enter the Name</p>
                                    <input
                                        name='name'
                                        placeholder='Name'
                                        type='text'
                                        onChange={event => setName(event.target.value)}
                                        id={styles.gap}
                                        value={name}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.third}>
                                    <p>Enter the Company Name</p>
                                    <input
                                        name='CompanyName'
                                        placeholder='Company Name'
                                        type='text'
                                        onChange={event => setCompanyname(event.target.value)}
                                        id={styles.gap}
                                        value={companyname}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.availability}>
                                    <p>Choose the Status</p>
                                    <Toggle status={status} setStatus={setStatus} />
                                </div>
                                <div className={styles.image}>
                                    <img src={image} alt="Logo"></img>
                                </div>
                                {/* <div className={styles.images}>
                                    <p>Upload the Image</p>
                                    <input
                                        name='image'
                                        type='file'
                                        onChange={event => setImage(event.target.files[0])}
                                        id={styles.file}
                                        required
                                    ></input>
                                </div> */}
                                <div className={styles.images}>
                                    <p>Upload the Image URL</p>
                                    <input
                                        name='image'
                                        type='url'
                                        onChange={event => setImage(event.target.value)}
                                        id={styles.gap}
                                        value={image}
                                        required
                                    ></input>
                                </div>
                                <div className={styles.fifth}>
                                    <p>Enter the Description</p>
                                    <textarea
                                        name='description'
                                        placeholder='Description'
                                        type='text'
                                        onChange={event => setDescription(event.target.value)}
                                        id={styles.gaptext}
                                        value={description}
                                        required
                                    ></textarea>
                                </div>
                                <div className={styles.button}>
                                    <input
                                        type='submit'
                                        className={styles.bttn}
                                        value='Update'
                                    />
                                    <input
                                        type='submit'
                                        className={styles.bttn}
                                        onClick={deleteData}
                                        value='Delete'
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}

export default TestimonialUpdate