import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Category.module.css';
import Spin from '../Spinner/Spinner';
import { API } from '../../config'
import { FaPlus } from 'react-icons/fa';

function Category() {
    const [categories, setCategories] = useState([]);
    const [spin, setSpin] = useState(true);

    const getData = async () => {
        const data = await fetch(
            `${API}/category/get`
        )
        const json = await data.json()
        // console.log(json);
        setCategories(json.allcategory);
        setSpin(false);
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {spin ? <Spin /> :
                <div className={styles.entirepage}>
                    <div className={styles.upper}>
                        <Link to="/">
                            <span className={styles.swag}>Website
                                <span className={styles.greater}> &gt; </span>
                            </span>
                        </Link>
                        <span className={styles.req}>Categories</span>
                    </div>
                    <div className={styles.heading}>
                        Categories
                    </div>
                    <div className={styles.addingbutton}>
                        <Link to='/category/add'>
                            <div className={styles.button}>
                                <FaPlus className={styles.addicon} />
                                Add Category
                            </div>
                        </Link>
                        {/* <div className={styles.button}>
                          Add via Sheet
                      </div> */}
                    </div>
                    <div className={styles.logosdisplay}>
                        <table className={styles.displaytable}>
                            <thead>
                                <tr className={styles.testimonialrowhead}>
                                    {/* <th>ID</th> */}
                                    <th>S No.</th>
                                    <th></th>
                                    <th>Category</th>
                                    <th>Description</th>
                                    {/* <th>Sub-Description</th> */}
                                    <th>Customisation</th>
                                    {/* <th>Custom Image</th>
                                    <th>Default Image</th>
                                    <th>Selected Image</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories.map((c, i) => {
                                        return (
                                            <tr className={styles.testimonialrow}>
                                                {/* <td>{c._id}</td> */}
                                                <td>{c.sortId}</td>
                                                <td className={styles.categoryname}><img src={c.images[0]} alt="Logo" className={styles.logos}></img></td>
                                                <td>{c.name}</td>
                                                <td>{c.description}</td>
                                                {/* <td>{c.subDescription.length!==0 ? c.subDescription : "-"}</td> */}
                                                <td>{c.custom ? "True" : "False"}</td>
                                                {/* <td>{c.custom ? <img src={c.someField} alt="Logo" className={styles.logos}></img> : "-"}</td>
                                                <td><img src={c.images[1]} alt="Logo" className={styles.logos}></img></td> */}
                                                <td className={styles.edit}>
                                                    <Link to={`/category/${c._id}`}>
                                                        <div >Edit</div>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default Category