import React, { useEffect, useState } from 'react'
import styles from './ClientUpdate.module.css'
import { Link, useParams } from "react-router-dom";
import Popup from '../Popup/Popup';
import Spinner from '../Spinner/Spinner'
import axios from 'axios';
import {API} from '../../config'
import { useNavigate } from 'react-router-dom';

function ClientUpdate() {
  const [order, setOrder] = useState();
  const [name, setName] = useState();
  const [logo, setLogo] = useState();
  const [value, setValue] = useState();
  const [client, setClient] = useState([]);
  const [spin, setSpin] = useState(true);
  const [spinn, setSpinn] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const params = useParams();

  const change = () => {
    setShow(false);
  };

  const getData = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: 'Fetch PUT Request Example' })
    };
    const response = await fetch(`${API}/companyList/${params.id}`, requestOptions);
    const data = await response.json();
    setClient(data);
    setSpin(false);
    setOrder(data.order_no);
    setLogo(data.logo_url);
    setName(data.company_name);
    setValue(data.c_value);
  }

  useEffect(() => {
    getData();
  }, [])

  const deleteData = () => {
    axios.delete(`${API}/companyList/${params.id}`)
    navigate('/clientlogos')
  }

  const saveData = async (event) => {
    event.preventDefault();
    event.target.reset();
    window.scrollTo(0, 0);
    setSpinn(true);
    console.log(logo);
    const data = {
      order_no: order,
      company_name: name,
      logo_url: logo,
      value: value,
    };
    console.log(data);
    axios
      .put(`${API}/companyList/${params.id}`, data)
      .then((res) => {
        console.log('Done')
        setShow(true);
        setSpinn(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {spinn ? <Spinner /> : null}
      {show ? <Popup show={show} switch={change} message="Updated Successfully" /> : null}
      {
        spin ? <Spinner /> :
          <div className={styles.clientpage}>
            <div className={styles.heading}>
              <h1 className={styles.details}>Update/Delete Client Logo</h1>
              <form onSubmit={saveData}>
                <div className={styles.items}>
                  <div className={styles.first}>
                    <p>Enter the Order Number</p>
                    <input
                      name='order_no'
                      placeholder='Order Number'
                      type='number'
                      value={order}
                      onChange={event => setOrder(event.target.value)}
                      id={styles.gap}
                      required
                    ></input>
                  </div>
                  <div className={styles.second}>
                    <p>Enter the Company Name</p>
                    <input
                      name='company_name'
                      placeholder='Category Name'
                      type='text'
                      value={name}
                      onChange={event => setName(event.target.value)}
                      id={styles.gap}
                      required
                    ></input>
                  </div>
                  {/* <div className={styles.images}>
                    <img src={logo} alt="Logo"></img>
                    <p>Upload the Changed Logo</p>
                    <input
                      name='logo_url'
                      type='file'
                      onChange={event => setLogo(event.target.files[0])}
                      id={styles.file}
                      required
                    ></input>
                  </div> */}
                  <div className={styles.images}>
                    <img src={logo} alt="Logo" className={styles.imglogo}></img>
                    <p>Enter the Logo Url</p>
                    <input
                      name='logo_url'
                      type='url'
                      placeholder='Image URL'
                      onChange={event => setLogo(event.target.value)}
                      id={styles.gap}
                      value={logo}
                    ></input>
                  </div>
                  <div className={styles.fifth}>
                    <p>Enter the C value</p>
                    <input
                      name='c_value'
                      placeholder='C value'
                      type='number'
                      value={value}
                      onChange={event => setValue(event.target.value)}
                      id={styles.gap}
                      required
                    ></input>
                  </div>
                  <div className={styles.button}>
                    <input
                      type='submit'
                      className={styles.bttn}
                      style={{ marginRight: "24px" }}
                      value='Update'
                    />
                    <input
                      type='reset'
                      className={styles.bttn}
                      onClick={deleteData}
                      value='Delete'
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
      }
    </>
  )
}

export default ClientUpdate