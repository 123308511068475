import React, { useRef, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Navbar.css'
import hustlemad from '../../images/Navbar/Hustlemad.svg'
import phonelogo from '../../images/Navbar/Contact.svg'
import plus from '../../images/Navbar/plus.svg'
import About from '../../images/Navbar/About.svg'
import FAQ from '../../images/Navbar/FAQ.svg'
import ContactUs from '../../images/Navbar/ContactUs.svg'
import Hamburger from '../../images/Navbar/Hamburger.svg'
import close from '../../images/Navbar/close.svg'
import rocket from '../../images/Navbar/Rocket.svg'
// import Footer from '../footer/footer.js'

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [productopen, setProductOpen] = useState(false)
  const [companyopen, setCompanyOpen] = useState(false)
  const [partneropen, setPartnerOpen] = useState(false)

  const showproduct = () => {
    setProductOpen(!productopen)
    setCompanyOpen(false)
  }

  const showcompany = () => {
    setCompanyOpen(!companyopen)
    setProductOpen(false)
  }

  const showpartners = () => {
    setPartnerOpen(!partneropen)
    setProductOpen(false)
    setCompanyOpen(false)
  }

  return (
    <>
      <div className='entirenavbar'>
        <div className='left'>
          <div className='logo'>
            <NavLink to='/'>
              <img className='hustlemadlogo' src={hustlemad} alt='' />
            </NavLink>
          </div>
          <div className='content'>
            <ul className='menu-links'>
              <NavLink to='/'>
                <li className='home'>Admin Portal</li>
              </NavLink>
              <li className='website' onClick={showcompany}>
                Website
                <img src={plus} className='pl'></img>
                <div className='websiteoptions'>
                  <NavLink to='/products'>
                    <div className='building'>
                      Products
                    </div>
                  </NavLink>
                  <NavLink to='/category'>
                    <div className='building'>
                      Categories
                    </div>
                  </NavLink>
                  <NavLink to=''>
                    <div className='building'>
                      Preset Packs
                    </div>
                  </NavLink>
                  <NavLink to='/clientlogos'>
                    <div className='building'>
                      Client Logos
                    </div>
                  </NavLink>
                  <NavLink to='/sellerlogos'>
                    <div className='building'>
                      Seller Logos
                    </div>
                  </NavLink>
                  <NavLink to='/testimonials'>
                    <div className='building'>
                      Testimonials
                    </div>
                  </NavLink>
                  <NavLink to=''>
                    <div className='building'>
                      Teams
                    </div>
                  </NavLink>
                  <NavLink to=''>
                    <div className='hustlemadstore'>
                      FAQ
                    </div>
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='right'>
          <div className='contact-info'>
            <a href='https://calendly.com/vipulg/20min' target='_blank'>
              <div className='call'>
                <img src={phonelogo} className='phonelogo'></img>
                <p>Schedule a Call </p>
              </div>
            </a>
          </div>
          <div className='hamburger' onClick={() => setMobileOpen(true)}>
            <img src={Hamburger} className='ham'></img>
          </div>
        </div>
      </div>
      {mobileOpen ? (
        <div className='mobileham'>
          <div className='closeimg' onClick={() => setMobileOpen(false)}>
            <img src={close} alt='close'></img>
          </div>
          <div className='mobile-left'>
            <div className='mobile-logo'>
              <NavLink to='/'>
                <img
                  className='mobile-hustlemadlogo'
                  onClick={() => setMobileOpen(false)}
                  src={hustlemad}
                  alt=''
                />
              </NavLink>
            </div>
            <div className='mobile-content'>
              <ul className='mobile-menu-links'>
                <NavLink to='/'>
                  <li className='home' onClick={() => setMobileOpen(false)}>
                    Home
                  </li>
                </NavLink>
                {/* <li className='product' onClick={showproduct}>
                  Products
                    <div className='productoptions'>
                      <NavLink to='/catalogue'>
                        <div
                          className='buildings'
                          onClick={() => setMobileOpen(false)}
                        >
                          <img src={Build} className='build'></img>Build a Box
                        </div>
                      </NavLink>
                      <a href='https://store.hustlemad.com' target='_blank'>
                        <div
                          className='hustlemadstore'
                          onClick={() => setMobileOpen(false)}
                        >
                          <img src={Store} className='store'></img>Hustlemad
                          Store
                        </div>
                      </a>
                    </div>
                </li> */}
                <li className='product' onClick={showcompany}>
                  Company
                  <div className='companyoptions'>
                    <NavLink to='/about'>
                      <div
                        className='building'
                        onClick={() => setMobileOpen(false)}
                      >
                        <img src={About} className='build'></img>About Us
                      </div>
                    </NavLink>
                    <NavLink to='/contact'>
                      <div
                        className='building'
                        onClick={() => setMobileOpen(false)}
                      >
                        <img src={ContactUs} className='build'></img>Contact Us
                      </div>
                    </NavLink>
                    <NavLink to='/faq'>
                      <div
                        className='hustlemadstore'
                        onClick={() => setMobileOpen(false)}
                      >
                        <img src={FAQ} className='build'></img>FAQ
                      </div>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink to="/catalogue">
                    <div className='hustlemadsbuild'
                      onClick={() => setMobileOpen(false)}>Build a Box</div>
                  </NavLink>
                </li>
                <li className='hustlemads' onClick={showpartners}>
                  Partner
                  <div className='partneroptions'>
                    <NavLink to='/partner'>
                      <div
                        className='building'
                        onClick={() => setMobileOpen(false)}
                      >
                        <img src={About} className='build'></img>Be our Partner
                      </div>
                    </NavLink>
                    <NavLink to='/allcompanies'>
                      <div
                        className='building'
                        onClick={() => setMobileOpen(false)}
                      >
                        <img src={ContactUs} className='build'></img>Our Partners
                      </div>
                    </NavLink>
                  </div>
                </li>
                {/* <li>
                  <NavLink to="/partner">
                    <div className='hustlemads' onClick={() => setMobileOpen(false)}>Partner</div>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='rocketimg'>
            <img src={rocket} alt='rocket'></img>
          </div>
          {/* <Footer /> */}
        </div>
      ) : null}
    </>
  )
}

export default Navbar
