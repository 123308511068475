import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import styles from './CategoryUpdate.module.css';
import Toggle from '../../utils/Toggle/Toggle';
import Spinner from '../Spinner/Spinner'
import {API} from '../../config'
import Popup from '../Popup/Popup';

function CategoryUpdate() {
  const [category, setCategory] = useState();
  const [sort, setSort] = useState();
  const [name, setName] = useState();
  const [nonSelectedImage, setNonSelectedImage] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [customImage, setCustomImage] = useState();
  const [status, setStatus] = useState(false);
  const [description, setDescription] = useState();
  const [subDescription, setSubDescription] = useState();
  const [show, setShow] = useState(false);
  const [spinn, setSpinn] = useState(false);
  const [spin, setSpin] = useState(true);
  const params = useParams();
  let navigate = useNavigate();

  const change = () => {
    setShow(false);
  };

  const getData = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }
    };
    const response = await fetch(`${API}/category/${params.id}`, requestOptions);
    const data = await response.json();
    setCategory(data);
    setSpin(false);
    setSort(data.sortId);
    setName(data.name);
    setNonSelectedImage(data.images[0]);
    setSelectedImage(data.images[1]);
    setDescription(data.description);
    setSubDescription(data.subDescription);
    setStatus(data.custom);
    setCustomImage(data.someField);
  }

  useEffect(() => {
    getData();
  }, [])

  const deleteData = () => {
    axios.delete(`${API}/category/${params.id}`)
    navigate('/category')
  }

  useEffect(()=>{
    saveData();
  },[subDescription])

  const saveData = async (event) => {
    event.preventDefault();
    event.target.reset();
    window.scrollTo(0, 0);
    setSpinn(true);
    const data = {
      sortId: sort,
      name: name,
      images: [nonSelectedImage, selectedImage],
      description: description,
      subDescription: subDescription,
      custom: status,
      someField: status ? customImage : "",
      createdBy: "60e9c7db11ab1428accf8278",
      updatedBy: "60e9c7db11ab1428accf8278",
      deletedBy: "60e9c7db11ab1428accf8278",
    };
    console.log(data);
    axios.put(`${API}/category/${params.id}`, data)
      .then((res) => {
        console.log('Done')
        setShow(true);
        setSpinn(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {spinn ? <Spinner /> : null}
      {show ? <Popup show={show} switch={change} message="Updated Successfully" /> : null}
      {spin ? <Spinner /> :
        <div className={styles.categorycontainer}>
          <div className={styles.heading}>
            <h1 className={styles.details}>Enter the Category Update/Delete Details</h1>
            <form onSubmit={saveData}>
              <div className={styles.items}>
                <div className={styles.first}>
                  <p>Enter the Sort Id</p>
                  <input
                    name='sortid'
                    placeholder='Sort ID'
                    type='number'
                    onChange={event => setSort(event.target.value)}
                    id={styles.gap}
                    value={sort}
                    required
                  ></input>
                </div>
                <div className={styles.second}>
                  <p>Enter the Category Name</p>
                  <input
                    name='fullname'
                    placeholder='Category Name'
                    type='text'
                    onChange={event => setName(event.target.value)}
                    id={styles.gap}
                    value={name}
                    required
                  ></input>
                </div>
                {/* <div className={styles.images}>
            <div className={styles.third}>
              <p>Upload the Image (Unselected)</p>
              <input
                name='fullname'
                placeholder='Category Name'
                type='file'
                // onChange={event => setName(event.target.value)}
                id={styles.file}
                required
              ></input>
            </div>
            <div className={styles.fourth}>
              <p>Upload the Image (Selected)</p>
              <input
                name='fullname'
                placeholder='Category Name'
                type='file'
                // onChange={event => setName(event.target.value)}
                id={styles.file}
                required
              ></input>
            </div>
          </div> */}
                <div className={styles.imageurl}>
                  <img src={nonSelectedImage} alt="Non-Selected Image"></img>
                  <p>Enter the Non-Selected Image Url</p>
                  <input
                    name='imageurl'
                    placeholder='Image URL'
                    type='url'
                    onChange={event => setNonSelectedImage(event.target.value)}
                    id={styles.gap}
                    value={nonSelectedImage}
                    required
                  ></input>
                </div>
                <div className={styles.selectedimageurl}>
                  <img src={selectedImage} alt="Non-Selected Image"></img>
                  <p>Enter the Selected Image Url</p>
                  <input
                    name='selectedimageurl'
                    placeholder='Selected Image URL'
                    type='url'
                    onChange={event => setSelectedImage(event.target.value)}
                    id={styles.gap}
                    value={selectedImage}
                    required
                  ></input>
                </div>
                <div className={styles.fifth}>
                  <p>Enter the Description</p>
                  <textarea
                    name='fullname'
                    placeholder='Description'
                    type='text'
                    onChange={event => setDescription(event.target.value)}
                    id={styles.gapdesc}
                    value={description}
                    required
                  ></textarea>
                </div>
                <div className={styles.sixth}>
                  <p>Enter the SubDescription</p>
                  <textarea
                    name='fullname'
                    placeholder='Sub-Description'
                    type='text'
                    onChange={event => setSubDescription(event.target.value)}
                    id={styles.gapdesc}
                    value={subDescription}
                    required
                  ></textarea>
                </div>
                <div className={styles.availability}>
                  <p style={{marginBottom:"16px"}}>Do you wanna allow Custom Products on this Category ?</p>
                  <Toggle status={status} setStatus={setStatus} />
                </div>
                {
                  status ? <div className={styles.sixth}>
                    <img src={customImage} alt="Non-Selected Image"></img>
                    <p>Enter the Custom Image URL</p>
                    <input
                      name='customimage'
                      placeholder='Custom Image URL'
                      type='url'
                      onChange={event => setCustomImage(event.target.value)}
                      id={styles.gap}
                      value={customImage}
                      required
                    ></input>
                  </div> : null
                }
                <div className={styles.button}>
                  <input
                    type='submit'
                    className={styles.bttn}
                    value='Update'
                  />
                  <input
                    type='submit'
                    className={styles.bttn}
                    onClick={deleteData}
                    value='Delete'
                  />
                </div>
              </div>
            </form>
          </div>
        </div>}
    </>
  )
}

export default CategoryUpdate