import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Product.module.css';
import Spin from '../Spinner/Spinner';
import {API} from '../../config'
import { FaPlus, FaUpload, FaDownload, FaArrowsAlt } from 'react-icons/fa';

function Product() {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [spin, setSpin] = useState(true);

    const getCategories = async () => {
        const data = await fetch(
            `${API}/category/get`
        )
        const json = await data.json()
        // console.log(json);
        setCategories(json.allcategory);
        getData()
    }

    useEffect(() => {
        getCategories()
    }, [])

    const getData = async () => {
        const data = await fetch(
            `${API}/productList`
        )
        const json = await data.json()
        setProducts(json.products);
        setSpin(false);
    }

    useEffect(() => {
        getData()
    }, [])

    const DownloadSheet = async () =>{
        const data = await fetch(
            `${API}/`
        )
    }

    return (
        <>
            {spin ? <Spin /> :
                <div className={styles.entirepage}>
                    <div className={styles.upper}>
                        <Link to="/">
                            <span className={styles.swag}>Website
                                <span className={styles.greater}> &gt; </span>
                            </span>
                        </Link>
                        <span className={styles.req}>Products</span>
                    </div>
                    <div className={styles.heading}>
                        Products
                    </div>
                    <div className={styles.addingbutton}>
                        <Link to='/products/add'>
                            <div className={styles.button}>
                                <FaPlus className={styles.addicon} />
                                Add Product
                            </div>
                        </Link>
                        <div className={styles.button}>
                            <FaUpload className={styles.addicon} />
                            Upload via Sheet
                        </div>
                        <div className={styles.button} onClick={DownloadSheet}>
                            <FaDownload className={styles.addicon} />
                            Download Sheet
                        </div>
                    </div>
                    <div className={styles.logosdisplay}>
                        <table className={styles.displaytable}>
                            <thead>
                                <tr className={styles.productrowhead}>
                                    <th>SN</th>
                                    <th></th>
                                    <th>Products</th>
                                    <th>Brand</th>
                                    <th>Type</th>
                                    <th>Show</th>
                                    <th>Price</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((u, index) => {
                                    return (
                                        <>
                                            <td className={styles.categoryname}>{u.name}</td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            <td className={styles.categoryname}></td>
                                            {
                                                products.map((c, i) => {
                                                    if (c.categoryId === u._id) {
                                                        return (
                                                            <tr className={styles.productrow}>
                                                                {/* <td>{c._id}</td> */}
                                                                <td>{c.sortId}</td>
                                                                <td><img src={c.image[0]} alt="Logo" className={styles.logos}></img></td>
                                                                <td className={styles.productname}>{c.name}</td>
                                                                <td>{c.brandName}</td>
                                                                {
                                                                    c.productType === 'C' ?
                                                                        <td><span style={{ backgroundColor: "#DFC6FF", padding: "8px 16px", borderRadius: "4px" }}>{c.productType}</span></td> :
                                                                        c.productType === 'DC' ?
                                                                            <td><span style={{ backgroundColor: "#CEE2FF", padding: "8px 16px", borderRadius: "4px" }}>{c.productType}</span></td> : c.productType === 'NV' ?
                                                                                <td><span style={{ backgroundColor: "#FFD0C4", padding: "8px 16px", borderRadius: "4px" }}>{c.productType}</span></td> : c.productType === 'IP' ?
                                                                                    <td><span style={{ backgroundColor: "#FFF1CA", padding: "8px 16px", borderRadius: "4px" }}>{c.productType}</span></td> :
                                                                                    <td><span style={{ backgroundColor: "#FFC5C6", padding: "8px 16px", borderRadius: "4px" }}>{c.productType}</span></td>
                                                                }
                                                                <td>{c.available ? "True" : "False"}</td>
                                                                <td className={styles.desc}>₹ {Math.round(1 * ((c.price[0].cost + (1 * c.price[0].customisation)) + (c.price[0].margin * (c.price[0].cost + (1 * c.price[0].customisation)))))}</td>
                                                                <td className={styles.edit}>
                                                                    <Link to={`/products/${c._id}`}>
                                                                        <div>Edit</div>
                                                                    </Link>
                                                                </td>
                                                                <td><FaArrowsAlt /></td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default Product