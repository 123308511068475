import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import Entry from './components/Entry/Entry'
import Category from './components/Categories/Category'
import Testimonials from './components/Testimonials/Testimonials';
import ClientLogos from './components/ClientLogos/ClientLogos';
import ClientLogosPage from './components/ClientLogosPage/ClientLogosPage';
import SellerLogos from './components/SellerLogos/SellerLogos';
import ClientUpdate from './components/ClientLogos/ClientUpdate';
import ClientPageUpdate from './components/ClientLogosPage/ClientPageUpdate';
import ClientPageAdd from './components/ClientLogosPage/ClientPageAdd';
import ClientAdd from './components/ClientLogos/ClientAdd';
import SellerAdd from './components/SellerLogos/SellerAdd';
import SellerUpdate from './components/SellerLogos/SellerUpdate';
import TestimonialAdd from './components/Testimonials/TestimonialAdd';
import TestimonialUpdate from './components/Testimonials/TestimonialUpdate';
import Product from './components/Product/Product';
import Navbar from './components/Navbar/Navbar';
import CategoryAdd from './components/Categories/CategoryAdd';
import CategoryUpdate from './components/Categories/CategoryUpdate';
import ProductAdd from './components/Product/ProductAdd';
import LoginPage from './LoginPage/LoginPage';
import ProductUpdate from './components/Product/ProductUpdate';
import ProductVariant from './components/Product/ProductVariant';

export function ProtectedRoute({ children }) {
  if (localStorage.getItem("user")) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
}

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={(<ProtectedRoute><Entry /></ProtectedRoute>)} />
          <Route path='/category' exact element={(<ProtectedRoute><Category /></ProtectedRoute>)} />
          <Route path='/category/:id' exact element={(<ProtectedRoute><CategoryUpdate /></ProtectedRoute>)} />
          <Route path='/category/add' exact element={(<ProtectedRoute><CategoryAdd /></ProtectedRoute>)} />
          <Route path='/clientlogos' exact element={(<ProtectedRoute><ClientLogos /></ProtectedRoute>)} />
          <Route path='/clientlogos/:id' exact element={(<ProtectedRoute><ClientUpdate /></ProtectedRoute>)} />
          <Route path='/clientlogos/add' element={(<ProtectedRoute><ClientAdd /></ProtectedRoute>)} />
          <Route path='/clientlogospage' exact element={(<ProtectedRoute><ClientLogosPage /></ProtectedRoute>)} />
          <Route path='/clientlogospage/:id' exact element={(<ProtectedRoute><ClientPageUpdate /></ProtectedRoute>)} />
          <Route path='/clientlogospage/add' element={(<ProtectedRoute><ClientPageAdd /></ProtectedRoute>)} />
          <Route path='/sellerlogos' exact element={(<ProtectedRoute><SellerLogos /></ProtectedRoute>)} />
          <Route path='/sellerlogos/:id' exact element={(<ProtectedRoute><SellerUpdate /></ProtectedRoute>)} />
          <Route path='/sellerlogos/add' element={(<ProtectedRoute><SellerAdd /></ProtectedRoute>)} />
          <Route path='/testimonials' exact element={(<ProtectedRoute><Testimonials /></ProtectedRoute>)} />
          <Route path='/testimonials/add' exact element={(<ProtectedRoute><TestimonialAdd /></ProtectedRoute>)} />
          <Route path='/testimonials/:id' exact element={(<ProtectedRoute><TestimonialUpdate /></ProtectedRoute>)} />
          <Route path='/products' exact element={(<ProtectedRoute><Product /></ProtectedRoute>)} />
          <Route path='/products/add' exact element={(<ProtectedRoute><ProductAdd /></ProtectedRoute>)} />
          <Route path='/products/:id' exact element={(<ProtectedRoute><ProductUpdate /></ProtectedRoute>)} />
          <Route path='/product/:id' exact element={(<ProtectedRoute><ProductVariant /></ProtectedRoute>)} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
