import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Testimonials.module.css';
import Spin from '../Spinner/Spinner';
import {API} from '../../config'
import { FaPlus } from 'react-icons/fa';

function Testimonials() {
    const [testimonials, setTestimonials] = useState([]);
    const [spin, setSpin] = useState(true);

    const getData = async () => {
        const data = await fetch(
            `${API}/testimonial/get`
        )
        const json = await data.json()
        setTestimonials(json.testimonials);
        setSpin(false);
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {spin ? <Spin /> :
                <div className={styles.entirepage}>
                    <div className={styles.upper}>
                        <Link to="/">
                            <span className={styles.swag}>Website
                                <span className={styles.greater}> &gt; </span>
                            </span>
                        </Link>
                        <span className={styles.req}>Testimonials</span>
                    </div>
                    <div className={styles.heading}>
                        Testimonials
                    </div>
                    <div className={styles.addingbutton}>
                        <Link to='/testimonials/add'>
                            <div className={styles.button}>
                                <FaPlus className={styles.addicon} />
                                Add Testimonial
                            </div>
                        </Link>
                        {/* <div className={styles.button}>
                          Add via Sheet
                      </div> */}
                    </div>
                    <div className={styles.logosdisplay}>
                        <table className={styles.displaytable}>
                            <thead>
                                <tr className={styles.testimonialrowhead}>
                                    {/* <th>ID</th> */}
                                    <th>S. No.</th>
                                    <th>Name</th>
                                    <th>Avatar</th>
                                    <th>Company Name</th>
                                    <th>Show</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testimonials.map((c, i) => {
                                        return (
                                            <tr className={styles.testimonialrow}>
                                                {/* <td>{c._id}</td> */}
                                                <td>{c.sortId}</td>
                                                <td>{c.name}</td>
                                                <td><img src={c.image} alt="Logo" className={styles.logos}></img></td>
                                                <td>{c.companyName}</td>
                                                <td>{c.status ? "True" : "False"}</td>
                                                <td className={styles.desc}>{c.description}</td>
                                                <td className={styles.edit}>
                                                    <Link to={`/testimonials/${c._id}`}>
                                                        <div >Edit</div>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default Testimonials