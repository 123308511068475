import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import styles from './ProductVariantEdit.module.css'
import { API } from '../../config';
import Spin from '../Spinner/Spinner';
import Popup from '../Popup/Popup';
import Backdrop from '../Backdrop/Backdrop'
import close from '../../images/close.svg'
import { FaPen, FaTrash } from 'react-icons/fa';

function ProductVariantEdit(props) {
    // console.log(props);
    const [size, setSize] = useState(['']);
    const [image, setImage] = useState(props.variantData.images);
    const [inventory, setInventory] = useState(['']);
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [color, setColor] = useState(props.variantData.colorName);
    const [hexcolor, setHexcolor] = useState(props.variantData.hexColor);
    const [variable, setVariable] = useState(props.variantData.varName);
    const [sku, setSku] = useState(props.variantData.sku);
    const [show, setShow] = useState(false);
    const [spinn, setSpinn] = useState(false);
    const params = useParams();
    let navigate = useNavigate();

    const getProductData = async () => {
        const data = await fetch(
            `${API}/ProductList/${params.id}`
        )
        const json = await data.json()
        setName(json[0].name);
        setType(json[0].productType);
    }

    useEffect(() => {
        getProductData()
    }, [])

    const InventoryEntry = () => {
        setInventory([...inventory, `Entry ${inventory.length}`]);
    };

    const ImageEntry = () => {
        setImage([...image, `Entry ${image.length}`]);
    };

    const SizeEntry = () => {
        setSize([...size, `Entry ${size.length}`]);
    };

    const saveImage = (e, index) => {
        let data = [...image];
        data[index] = e.target.value;
        setImage(data);
    }

    const change = () => {
        setShow(false);
    };

    const saveData = async (event) => {
        event.preventDefault();
        event.target.reset();
        window.scrollTo(0, 0);
        setSpinn(true);
        const data = {
            productId: params.id,
            productType: "",
            sizeAndInventory: size,
            images: image,
            colorName: color,
            hexColor: hexcolor,
            varName: variable,
            sku: sku,
            createdBy: "60e9c7db11ab1428accf8278",
            updatedBy: "60e9c7db11ab1428accf8278",
            deletedBy: "60e9c7db11ab1428accf8278"
        };
        // console.log(data);
        axios.put(`${API}/productVariant/${props.variantData._id}`, data)
            .then((res) => {
                // console.log('Done')
                setShow(true);
                setSpinn(false);
                navigate(`/products/`);
                props.setVariantEdit(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const closeContainer = () => {
        props.setVariantEdit(false);
    }

    return (
        <>
            {spinn ? <Spin /> : null}
            {show ? <Popup show={show} switch={change} message="Updated Successfully" /> : null}
            <Backdrop show={props.show} switch={props.switch} />
            <div className={styles.categorycontainer}>
                <div className={styles.closebtn}>
                    <img src={close} alt='CloseImage' onClick={closeContainer}></img>
                </div>
                <h1 className={styles.details}>Edit Variant</h1>
                <form onSubmit={saveData}>.
                    <div className={styles.buttondisplay}>
                        <div className={styles.button}>
                            <div className={styles.icon}>
                                <FaPen />
                            </div>
                            <input
                                type='submit'
                                className={styles.bttn}
                                style={{ marginRight: "20px" }}
                                value='Edit'
                            />
                        </div>
                        <div className={styles.button}>
                            <div className={styles.icon}>
                                <FaTrash />
                            </div>
                            <input
                                type='reset'
                                className={styles.bttn}
                                // onClick={() => deleteVariantData()}
                                value='Delete'
                            />
                        </div>
                    </div>
                    <div className={styles.heading}>
                        <div className={styles.items}>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Product Id : </div>
                                <input
                                    name='fullname'
                                    type='text'
                                    value={params.id}
                                    id={styles.gap}
                                    disabled
                                ></input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Product Name : </div>
                                <input
                                    name='fullname'
                                    type='text'
                                    value={name}
                                    id={styles.gap}
                                    disabled
                                ></input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Product Type : </div>
                                <input
                                    name='type'
                                    type='text'
                                    id={styles.gap}
                                    value={type === 'UP' ? "Unquantifiable Products" : type === 'C' ? "Colored Products" : type === 'DC' ? "Dual Colored Products" : type === 'NV' ? "Named Variants" : "Individual Products"}
                                    disabled
                                >
                                </input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Sizes : </div>
                                <div className={styles.sizechart}>
                                    {
                                        size.map((i, index) => {
                                            return (
                                                <input
                                                    name='fullname'
                                                    placeholder='Sizes'
                                                    type='text'
                                                    onChange={event => setSize(event.target.value)}
                                                    id={styles.gap}
                                                    disabled
                                                ></input>
                                            )
                                        })
                                    }
                                    <input
                                        name='sizechoice'
                                        type='submit'
                                        value='Add Another Size'
                                        style={{ cursor: "pointer" }}
                                        id={styles.gap}
                                        onClick={SizeEntry}
                                    ></input>
                                </div>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Images : </div>
                                <div className={styles.sizechart}>
                                    {
                                        image.map((i, index) => {
                                            return (
                                                <input
                                                    name='image'
                                                    placeholder='Image Links'
                                                    type='url'
                                                    onChange={event => saveImage(event, index)}
                                                    value={i}
                                                    id={styles.gap}
                                                    required
                                                ></input>
                                            )
                                        })
                                    }
                                    <input
                                        name='sizechoice'
                                        type='submit'
                                        value='Add Another Image'
                                        style={{ cursor: "pointer" }}
                                        id={styles.gap}
                                        onClick={ImageEntry}
                                    ></input>
                                </div>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Color/Colors : </div>
                                <input
                                    name='fullname'
                                    placeholder='Color Name'
                                    type='text'
                                    onChange={event => setColor(event.target.value)}
                                    value={color}
                                    id={styles.gap}
                                ></input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Hex Code : </div>
                                <input
                                    name='fullname'
                                    placeholder='Hex Code'
                                    type='text'
                                    onChange={event => setHexcolor(event.target.value)}
                                    value={hexcolor}
                                    id={styles.gap}
                                ></input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Variant Name : </div>
                                <input
                                    name='fullname'
                                    placeholder='Variant Name'
                                    type='text'
                                    onChange={event => setVariable(event.target.value)}
                                    value={variable}
                                    id={styles.gap}
                                ></input>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>Inventory : </div>
                                <div className={styles.sizechart}>
                                    {
                                        inventory.map((i, index) => {
                                            return (
                                                <input
                                                    name='fullname'
                                                    placeholder='Inventory'
                                                    type='text'
                                                    onChange={event => setInventory(event.target.value)}
                                                    id={styles.gap}
                                                    disabled
                                                ></input>
                                            )
                                        })
                                    }
                                    <input
                                        name='sizechoice'
                                        type='submit'
                                        value='Add Another Inventory'
                                        style={{ cursor: "pointer" }}
                                        id={styles.gap}
                                        onClick={InventoryEntry}
                                    ></input>
                                </div>
                            </div>
                            <div className={styles.first}>
                                <div className={styles.itemhead}>SKU : </div>
                                <input
                                    name='fullname'
                                    placeholder='SKU'
                                    type='text'
                                    onChange={event => setSku(event.target.value)}
                                    value={sku}
                                    id={styles.gap}
                                ></input>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ProductVariantEdit