import React, { useState } from 'react';
import Business from './Business';
import styles from './Entry.module.css';
import Website from './Website';

function Entry() {
    const [index, setIndex] = useState(0);
    return (
        <>
            <div className={styles.AdminEntry}>
                <div className={styles.pages}>
                    <div className={styles.toggle}>
                        <div className={index===0 ? styles.website : styles.websiteunactive} onClick={()=>setIndex(0)}>
                            <h4>Website</h4>
                        </div>
                        <div className={index===1 ? styles.businessactive : styles.business} onClick={()=>setIndex(1)}>
                            <h4>Business</h4>
                        </div>
                        <div className={styles.clients}>
                            <h4>Clients</h4>
                        </div>
                        <div className={styles.partners}>
                            <h4>Partners</h4>
                        </div>
                    </div>
                </div>
                {
                    index === 0 ? <Website /> : <Business />
                }
            </div>
        </>
    )
}

export default Entry