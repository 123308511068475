import React, { useState } from 'react'
import styles from './CategoryAdd.module.css'
import axios from "axios";
import Toggle from '../../utils/Toggle/Toggle';
import Spinner from '../Spinner/Spinner'
import {API} from '../../config'
import Popup from '../Popup/Popup';

function CategoryAdd() {
  const [sort, setSort] = useState();
  const [name, setName] = useState();
  const [nonSelectedImage, setNonSelectedImage] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [customImage, setCustomImage] = useState();
  const [status, setStatus] = useState(false);
  const [description, setDescription] = useState();
  const [subDescription, setSubDescription] = useState();
  const [show, setShow] = useState(false);
  const [spin, setSpin] = useState(false);

  const change = () => {
    setShow(false);
  };

  const saveData = async (event) => {
    event.preventDefault();
    event.target.reset();
    window.scrollTo(0, 0);
    setSpin(true);
    const data = {
      sortId: sort,
      name: name,
      images: [nonSelectedImage, selectedImage],
      description: description,
      subDescription: subDescription,
      custom: status,
      someField: customImage,
      createdBy: "60e9c7db11ab1428accf8278",
      updatedBy: "60e9c7db11ab1428accf8278",
      deletedBy: "60e9c7db11ab1428accf8278",
    };
    console.log(data);
    axios
      .post(`${API}/category/add`, data)
      .then((res) => {
        console.log('Done')
        setShow(true);
        setSpin(false);
        setStatus(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {spin ? <Spinner /> : null}
      {show ? <Popup show={show} switch={change} message="Added Successfully" /> : null}
      <div className={styles.categorycontainer}>
        <div className={styles.heading}>
          <h1 className={styles.details}>Enter the Category Details</h1>
          <form onSubmit={saveData}>
            <div className={styles.items}>
              <div className={styles.first}>
                <p>Enter the Sort Id</p>
                <input
                  name='sortid'
                  placeholder='Sort ID'
                  type='number'
                  onChange={event => setSort(event.target.value)}
                  id={styles.gap}
                  required
                ></input>
              </div>
              <div className={styles.second}>
                <p>Enter the Category Name</p>
                <input
                  name='fullname'
                  placeholder='Category Name'
                  type='text'
                  onChange={event => setName(event.target.value)}
                  id={styles.gap}
                  required
                ></input>
              </div>
              {/* <div className={styles.images}>
              <div className={styles.third}>
                <p>Upload the Image (Unselected)</p>
                <input
                  name='fullname'
                  placeholder='Category Name'
                  type='file'
                  // onChange={event => setName(event.target.value)}
                  id={styles.file}
                  required
                ></input>
              </div>
              <div className={styles.fourth}>
                <p>Upload the Image (Selected)</p>
                <input
                  name='fullname'
                  placeholder='Category Name'
                  type='file'
                  // onChange={event => setName(event.target.value)}
                  id={styles.file}
                  required
                ></input>
              </div>
            </div> */}
              <div className={styles.imageurl}>
                <p>Enter the Non-Selected Image Url</p>
                <input
                  name='imageurl'
                  placeholder='Image URL'
                  type='url'
                  onChange={event => setNonSelectedImage(event.target.value)}
                  id={styles.gap}
                  required
                ></input>
              </div>
              <div className={styles.selectedimageurl}>
                <p>Enter the Selected Image Url</p>
                <input
                  name='selectedimageurl'
                  placeholder='Selected Image URL'
                  type='url'
                  onChange={event => setSelectedImage(event.target.value)}
                  id={styles.gap}
                  required
                ></input>
              </div>
              <div className={styles.fifth}>
                <p>Enter the Description</p>
                <textarea
                  name='fullname'
                  placeholder='Description'
                  type='text'
                  onChange={event => setDescription(event.target.value)}
                  id={styles.gapdesc}
                  required
                ></textarea>
              </div>
              <div className={styles.sixth}>
                <p>Enter the SubDescription</p>
                <textarea
                  name='fullname'
                  placeholder='Sub-Description'
                  type='text'
                  onChange={event => setSubDescription(event.target.value)}
                  id={styles.gapdesc}
                  required
                ></textarea>
              </div>
              <div className={styles.availability}>
                <p>Do you wanna allow Custom Products on this Category ?</p>
                <Toggle status={status} setStatus={setStatus} />
              </div>
              {
                status ? <div className={styles.sixth}>
                  <p>Enter the Custom Image URL</p>
                  <input
                    name='customimage'
                    placeholder='Custom Image URL'
                    type='url'
                    onChange={event => setCustomImage(event.target.value)}
                    id={styles.gap}
                    required
                  ></input>
                </div> : null
              }
              <div className={styles.button}>
                <input
                  type='submit'
                  className={styles.bttn}
                  value='Submit'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CategoryAdd