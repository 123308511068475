import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './ClientLogos.module.css';
import Spin from '../Spinner/Spinner';
import { API } from '../../config'
import { FaPlus } from 'react-icons/fa'

function ClientLogosPage() {
    const [clientLogos, setClientLogos] = useState([]);
    const [spin, setSpin] = useState(true);

    const getData = async () => {
        const data = await fetch(
            `${API}/companyPageList`
        )
        const json = await data.json()
        setClientLogos(json.clients);
        setSpin(false);
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {spin ? <Spin /> :
                <div className={styles.entirepage}>
                    <div className={styles.upper}>
                        <NavLink to="/">
                            <span className={styles.swag}>Website
                                <span className={styles.greater}> &gt; </span>
                            </span>
                        </NavLink>
                        <span className={styles.req}>Client Logos Page</span>
                    </div>
                    <div className={styles.heading}>
                        Client Logos Page
                    </div>
                    <div className={styles.addingbutton}>
                        <Link to='/clientlogospage/add'>
                            <div className={styles.button}>
                                <FaPlus className={styles.addicon} />
                                Add Client Logo
                            </div>
                        </Link>
                        {/* <div className={styles.button}>
                            Add via Sheet
                        </div> */}
                    </div>
                    <div className={styles.logosdisplay}>
                        <table className={styles.displaytable}>
                            <thead>
                                <tr className={styles.clientrowhead}>
                                    {/* <th>ID</th> */}
                                    <th>S No.</th>
                                    <th>Company Name</th>
                                    <th>Logo</th>
                                    <th>C value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientLogos.map((c, i) => {
                                        return (
                                            <tr className={styles.clientrow}>
                                                {/* <td>{c._id}</td> */}
                                                <td>{c.order_no}</td>
                                                <td>{c.company_name}</td>
                                                <td><img src={c.logo_url} alt="Logo" className={styles.logos}></img></td>
                                                <td>{c.c_value}</td>
                                                <td className={styles.edit}>
                                                    <Link to={`/clientlogospage/${c._id}`}>
                                                        <div >Edit</div>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default ClientLogosPage