import React from 'react'
import styles from './Toggle.module.css'
import { BsCheckLg, BsXLg } from 'react-icons/bs'

function Toggle({ status, setStatus }) {
    return (
        <label className={styles.switch}>
            {status ?
                <input className={styles.switchinput} type="checkbox" onClick={() => setStatus(!status)} checked /> :
                <input className={styles.switchinput} type="checkbox" onClick={() => setStatus(!status)} />}
            <span className={styles.switchlabel} data-on="" data-off=""></span>
            <span className={styles.switchhandle}>
                {status ? <BsCheckLg className={styles.icon} fontSize="12px" /> : <BsXLg className={styles.icon} fontSize="12px" />}
            </span>
        </label>
    )
}

export default Toggle