import React from 'react'
import styles from './Business.module.css';
import { Link } from "react-router-dom";
import Products from '../../images/MainPage/Products.svg';
import Client from '../../images/MainPage/Client.svg';
import Partner from '../../images/MainPage/Partner.svg';
import Preset from '../../images/MainPage/Preset.svg';
import Team from '../../images/MainPage/Team.svg';

function Business() {
    return (
        <div className={styles.cardscontainer}>
            <Link to="products">
                <div className={styles.cards}>
                    <img src={Products} alt="Admin"></img>
                    <h2>Order Requests</h2>
                </div>
            </Link>
            <Link to="category">
                <div className={styles.cards}>
                    <img src={Preset} alt="Admin"></img>
                    <h2>Preset Packs</h2>
                </div>
            </Link>
            <Link to="clientlogos">
                <div className={styles.cards}>
                    <img src={Client} alt="Admin"></img>
                    <h2>Request Info</h2>
                </div>
            </Link>
            <Link to="sellerlogos">
                <div className={styles.cards}>
                    <img src={Partner} alt="Admin"></img>
                    <h2>Seller Leads</h2>
                </div>
            </Link>
            <Link to="">
                <div className={styles.cards}>
                    <img src={Team} alt="Admin"></img>
                    <h2>Contact Us Leads</h2>
                </div>
            </Link>
        </div>
    )
}

export default Business