import React, { useState } from 'react'
import axios from "axios";
import styles from './ClientAdd.module.css'
import Spinner from '../Spinner/Spinner'
import Popup from '../Popup/Popup';
import { API } from '../../config'
// import Cloudflare from '../../utils/Cloudflare/Cloudflare';

function ClientPageAdd() {
    const [order, setOrder] = useState();
    const [name, setName] = useState();
    const [logo, setLogo] = useState();
    const [value, setValue] = useState();
    // const [cloud, setCloud] = useState(false);
    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false);

    const change = () => {
        setShow(false);
    };

    const saveCloud = (event) => {
        // setCloud(true);
        event.preventDefault();
        event.target.reset();
        saveData();
    }

    const saveData = async () => {
        window.scrollTo(0, 0);
        setSpin(true);
        console.log(logo);
        const data = {
            order_no: order,
            company_name: name,
            logo_url: logo,
            value: value,
        };
        console.log(data);
        axios
            .post(`${API}/companyPageList`, data)
            .then((res) => {
                console.log('Done')
                setShow(true);
                setSpin(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <>
            {spin ? <Spinner /> : null}
            {show ? <Popup show={show} switch={change} message="Added Successfully" /> : null}
            <div className={styles.clientpage}>
                <div className={styles.heading}>
                    <h1 className={styles.details}>Enter the Client Logo Details</h1>
                    <form onSubmit={saveCloud}>
                        <div className={styles.items}>
                            <div className={styles.first}>
                                <p>Enter the Order Number</p>
                                <input
                                    name='order_no'
                                    placeholder='Order Number'
                                    type='number'
                                    onChange={event => setOrder(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.second}>
                                <p>Enter the Company Name</p>
                                <input
                                    name='company_name'
                                    placeholder='Category Name'
                                    type='text'
                                    onChange={event => setName(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            {/* <div className={styles.images}>
                                <p>Upload the Logo</p>
                                <input
                                    name='logo_url'
                                    type='file'
                                    onChange={event => setLogo(event.target.files[0])}
                                    id={styles.file}
                                ></input>
                            </div> */}
                            <div className={styles.images}>
                                <p>Enter the Logo Url</p>
                                <input
                                    name='logo_url'
                                    type='url'
                                    placeholder='Image URL'
                                    onChange={event => setLogo(event.target.value)}
                                    id={styles.gap}
                                ></input>
                            </div>
                            <div className={styles.fifth}>
                                <p>Enter the C value</p>
                                <input
                                    name='c_value'
                                    placeholder='C value'
                                    type='number'
                                    onChange={event => setValue(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.button}>
                                <input
                                    type='submit'
                                    className={styles.bttn}
                                    value='Submit'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* {
                cloud ? <Cloudflare logo={logo} setLogo={setLogo}></Cloudflare> : null
            } */}
        </>
    )
}

export default ClientPageAdd