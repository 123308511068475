import React, { useState } from 'react'
import axios from "axios";
import styles from './TestimonialAdd.module.css'
import Toggle from '../../utils/Toggle/Toggle';
import Spinner from '../Spinner/Spinner'
import {API} from '../../config'
import Popup from '../Popup/Popup';

function TestimonialAdd() {
    const [sort, setSort] = useState();
    const [name, setName] = useState();
    const [companyname, setCompanyname] = useState();
    const [image, setImage] = useState();
    const [status, setStatus] = useState(false);
    const [description, setDescription] = useState();
    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false);

    const change = () => {
        setShow(false);
    };

    const saveData = async (event) => {
        event.preventDefault();
        event.target.reset();
        window.scrollTo(0, 0);
        setSpin(true);
        const data = {
            sortId: sort,
            name: name,
            companyName: companyname,
            image: image,
            status: status,
            description: description
        };
        console.log(data);
        axios
            .post(`${API}/testimonial/add`, data)
            .then((res) => {
                console.log('Done')
                setShow(true);
                setSpin(false);
                setStatus(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <>
            {spin ? <Spinner /> : null}
            {show ? <Popup show={show} switch={change} message="Added Successfully" /> : null}
            <div className={styles.clientpage}>
                <div className={styles.heading}>
                    <h1 className={styles.details}>Enter the Testimonial Details</h1>
                    <form onSubmit={saveData}>
                        <div className={styles.items}>
                            <div className={styles.first}>
                                <p>Enter the SORT ID</p>
                                <input
                                    name='sortId'
                                    placeholder='Sort Id'
                                    type='number'
                                    onChange={event => setSort(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.second}>
                                <p>Enter the Name</p>
                                <input
                                    name='name'
                                    placeholder='Name'
                                    type='text'
                                    onChange={event => setName(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.third}>
                                <p>Enter the Company Name</p>
                                <input
                                    name='CompanyName'
                                    placeholder='Company Name'
                                    type='text'
                                    onChange={event => setCompanyname(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.availability}>
                                <p>Choose the Status</p>
                                <Toggle status={status} setStatus={setStatus} />
                            </div>
                            {/* <div className={styles.images}>
                            <p>Upload the Image</p>
                            <input
                                name='image'
                                type='file'
                                onChange={event => setImage(event.target.files[0])}
                                id={styles.file}
                                required
                            ></input>
                        </div> */}
                            <div className={styles.images}>
                                <p>Upload the Image URL</p>
                                <input
                                    name='image'
                                    type='url'
                                    onChange={event => setImage(event.target.value)}
                                    id={styles.gap}
                                    placeholder='Enter the Image URL'
                                    required
                                ></input>
                            </div>
                            <div className={styles.fifth}>
                                <p>Enter the Description</p>
                                <textarea
                                    name='description'
                                    placeholder='Description'
                                    type='text'
                                    onChange={event => setDescription(event.target.value)}
                                    id={styles.gaptext}
                                    required
                                ></textarea>
                            </div>
                            <div className={styles.button}>
                                <input
                                    type='submit'
                                    className={styles.bttn}
                                    value='Submit'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TestimonialAdd