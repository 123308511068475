import React, { useState } from 'react'
import axios from "axios";
import styles from './SellerAdd.module.css'
import Spinner from '../Spinner/Spinner'
import {API} from '../../config'
import Popup from '../Popup/Popup';

function SellerAdd() {
    const [order, setOrder] = useState();
    const [name, setName] = useState();
    const [logo, setLogo] = useState();
    const [value, setValue] = useState();
    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false);

    const change = () => {
        setShow(false);
    };

    const saveData = async (event) => {
        event.preventDefault();
        event.target.reset();
        window.scrollTo(0, 0);
        setSpin(true);
        const data = {
            order_no: order,
            brand_name: name,
            logo_url: logo,
            c_value: value,
        };
        console.log(data);
        axios
            .post(`${API}/brandLogo/add`, data)
            .then((res) => {
                console.log('Done')
                setShow(true);
                setSpin(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <>
            {spin ? <Spinner /> : null}
            {show ? <Popup show={show} switch={change} message="Added Successfully" /> : null}
            <div className={styles.clientpage}>
                <div className={styles.heading}>
                    <h1 className={styles.details}>Enter the Seller Logo Details</h1>
                    <form onSubmit={saveData}>
                        <div className={styles.items}>
                            <div className={styles.first}>
                                <p>Enter the Order Number</p>
                                <input
                                    name='order_no'
                                    placeholder='Order Number'
                                    type='number'
                                    onChange={event => setOrder(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.second}>
                                <p>Enter the Company Name</p>
                                <input
                                    name='brand_name'
                                    placeholder='Category Name'
                                    type='text'
                                    onChange={event => setName(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.images}>
                                <p>Upload the Logo</p>
                                <input
                                    name='logo_url'
                                    type='url'
                                    onChange={event => setLogo(event.target.value)}
                                    id={styles.gap}
                                    placeholder='Enter the Url'
                                    required
                                ></input>
                            </div>
                            <div className={styles.fifth}>
                                <p>Enter the C value</p>
                                <input
                                    name='c_value'
                                    placeholder='C value'
                                    type='number'
                                    onChange={event => setValue(event.target.value)}
                                    id={styles.gap}
                                    required
                                ></input>
                            </div>
                            <div className={styles.button}>
                                <input
                                    type='submit'
                                    className={styles.bttn}
                                    value='Submit'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SellerAdd